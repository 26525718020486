<template>
  <div class="padding">
    <img
      v-for="item in newsList"
      :key="item.id"
      :src="item.img"
      class="logo_image mr_30 mb_20"
    />
    <div class="flex justify-center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[30, 60, 90, 120]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getNewsPage } from '@/api/home';
export default {
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      size: 30,
      current: 1,
      newsList: []
    };
  },
  mounted() {
    if (this.id) {
      this.getNewsList();
    }
  },
  watch: {
    id() {
      this.getNewsList();
    }
  },
  methods: {
    getNewsList() {
      const obj = {
        id: this.id,
        current: this.current,
        size: this.size
      };
      getNewsPage(obj).then((res) => {
        this.newsList = res.data.records;
        this.total = res.data.total;
      });
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getNewsList();
    },
    handleSizeChange(val) {
      this.current = 1;
      this.size = val;
      this.getNewsList();
    }
  }
};
</script>
<style lang="scss" scoped>
.padding {
  padding: 40px 0 40px 46px;
  .logo_image {
    width: 180px;
    height: 180px;
    border: 1px solid #E6E6E6;
  }
}
</style>